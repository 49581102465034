import React from "react";
import { UserInfo } from "../component/Index";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
function EmailPage() {
  const { userProperties } = UserInfo({ data: ["email", "uid"] });
  const storedToken = localStorage.getItem("token");

  const handaleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://demo.urltd.online/auth/send-email",
        {
          email: userProperties.email,
          uid: userProperties.uid,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Action Successful.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Server retrun 401 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div className="container d-flex justify-content-center">
      <div className="card text-center p-4 mt-4" style={{ width: "30rem" }}>
        <div className="d-flex justify-content-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#D4F8DE",
              borderRadius: "360px",
            }}
          >
            <MdEmail style={{ color: "#329162" }} />
          </div>
        </div>

        <h4 className="mt-2">Please verify email</h4>
        <p className="text-white">
          We send a Email to{" "}
          <span className=" text-white fw-bold">{userProperties.email}</span> to
          active your account verifiy your email address{" "}
        </p>
        <p className="text-white">
          Just click on the link in the email to complete your signup. If you
          don't see it, you may need to check your spam folder.
        </p>
        <p className="text-white">Still can't find the email? No problem.</p>
        <button
          className="btn mb-4 cardx text-white fw-500"
          onClick={handaleSubmit}
        >
          Resend Email
        </button>
      </div>
    </div>
  );
}

export default EmailPage;
