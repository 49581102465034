import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";
import { LuHistory } from "react-icons/lu";

function Transactions() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("All");
  const storedToken = localStorage.getItem("token");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (newFilterType) => {
    setFilterType(newFilterType);
  };

  const filteredData = data.filter(
    (item) =>
      item.type === "Reward" &&
      item.tnx_id.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filterType === "All" || item.status === filterType)
  );

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.urltd.online/transactions",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [storedToken]);

  return (
    <div className="mt-4">
      <h5 className="mb-4">
        <LuHistory className="me-1" />
        Reward History
      </h5>
      <ThemeProvider theme={darkTheme}>
        <div>
          {/* Search input */}
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={handleSearchChange}
          />

          {/* Filter buttons */}
          <div className="mb-2">
            <button
              className={`btn btn-outline-primary me-2 ${
                filterType === "All" && "active"
              }`}
              onClick={() => handleFilterChange("All")}
            >
              All
            </button>
            <button
              className={`btn btn-outline-primary me-2 ${
                filterType === "pending" && "active"
              }`}
              onClick={() => handleFilterChange("pending")}
            >
              Pending
            </button>
            <button
              className={`btn btn-outline-primary ${
                filterType === "success" && "active"
              }`}
              onClick={() => handleFilterChange("success")}
            >
              Success
            </button>
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead className="cardx">
                <TableRow>
                  <TableCell>
                    <span className="bold">TRANX NO</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TOKENS</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TO </span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TYPE</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">STATUS</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TIME</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell className="text-center" colSpan={6}>
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .reverse()
                    .map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.tnx_id}</TableCell>
                        <TableCell>{item.tokes} XLMS</TableCell>
                        <TableCell>
                          {" "}
                          {item.wallet.length > 12
                            ? `${item.wallet.slice(0, 8)}...${item.wallet.slice(
                                -6
                              )}`
                            : item.wallet}
                        </TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>
                          <button
                            className={`badge p-1 bg-opacity-10 border  border-opacity-25  ${
                              item.status === "pending"
                                ? "bg-danger text-danger border-danger"
                                : "bg-success text-success border-success"
                            }`}
                          >
                            {item.status}
                          </button>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {new Date(item.created_at).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default Transactions;
