import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ParticleComponent from "../component/ParticleComponent";
import Settings from "../component/Settings";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { AiFillTwitterCircle } from "react-icons/ai";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { FaFacebook, FaTelegram } from "react-icons/fa";

function Login() {
  const { item } = Settings();
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (location.state && location.state.signupSuccess) {
      setAlertMessage(location.state.alertMessage);
      setShowSuccessAlert(true);
    }
  }, [location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const response = await axios.post(
          "https://demo.urltd.online/auth/login",
          {
            email: form.elements.formBasicEmail.value,
            password: form.elements.formBasicPassword.value,
          }
        );

        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem("token", token);
          let decodedToken = null;
          try {
            decodedToken = jwtDecode(token);
            if (decodedToken.role === "user") {
              navigate("/user/dashboard");
            } else {
              navigate("/admin/dashboard");
            }
          } catch (error) {
            console.error("Error decoding JWT:", error);
          }
        } else {
          setAlertMessage("Invalid credentials");
          setShowSuccessAlert(true);
        }
      } catch (error) {
        setAlertMessage("Invalid credentials");
        setShowSuccessAlert(true);
      }
    }

    setValidated(true);
  };

  return (
    <>
      <div className="vh-100">
        <ParticleComponent />

        <div className="container vh-100 d-flex justify-content-center align-items-center">
          <div
            className="card bg-darkX text-white moon"
            style={{ width: "27rem" }}
          >
            <h3 className="mb-2">Login</h3>
            <p>Welcome to Stellargold</p>
            {showSuccessAlert && (
              <Alert
                variant="success"
                onClose={() => setShowSuccessAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" required placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <div className="input-group d-flex">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <IoEyeOff /> : <IoEye />}
                  </Button>
                </div>
                <Link to="/reset-password" style={{ textDecoration: "none" }}>
                  <p className="mt-2">Forget Password?</p>
                </Link>
              </Form.Group>
              <div className="d-grid mt-1">
                <Button className="cardx" type="submit" size="xl">
                  Sign In
                </Button>
              </div>
            </Form>
            <p className="text-center mt-4">
              Don’t have an account?
              <Link to="/signup" style={{ textDecoration: "none" }}>
                <b className="text-primaryX"> Sign Up</b>
              </Link>
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <Link to={item.telegram} className="text-white-50">
                <div className="i">
                  <FaTelegram />
                </div>
              </Link>
              <Link to={item.facebook} className="text-white-50">
                <div className="i">
                  <FaFacebook />
                </div>
              </Link>
              <Link to={item.instagram} className="text-white-50">
                <div className="i">
                  <AiFillTwitterCircle />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
