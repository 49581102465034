import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Error, EmailPage } from "../pages/index";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const ProtectedRoute = ({ component: Component, roles }) => {
  const storedToken = localStorage.getItem("token");
  const [userrole, setUserrole] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let decodedToken = null;
    decodedToken = jwtDecode(storedToken);
    setUserrole(decodedToken.role);
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `https://demo.urltd.online/user/${decodedToken.results[0].uid}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setEmail(response.data.results[0].email_at);
        setUserrole(response.data.results[0].role);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storedToken]);

  if (storedToken === null || storedToken === undefined) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <div className="text-center mt-4">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (userrole === null) {
    return <div>Loading...</div>;
  }

  const isUserAuthorized = roles.some((role) => userrole.includes(role));
  if (isUserAuthorized) {
    if (email === null) {
      return <EmailPage />;
    }
    return <Component />;
  }

  return <Error />;
};

export default ProtectedRoute;
