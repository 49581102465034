import React from "react";
import { MdOutlineDashboard } from "react-icons/md";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { GiStoneBlock } from "react-icons/gi";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import UserInfo from "./UserInfo";
function Topnavbar() {
  const navigate = useNavigate();
  const { userProperties } = UserInfo({ data: ["role"] });

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <ToastContainer />
      <Navbar
        collapseOnSelect
        expand="lg"
        data-bs-theme="dark"
        className="p-3 top"
      >
        <Container>
          <Navbar.Brand href="/">
            <h5 className="logo">StellarRewards</h5>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {userProperties.role === "user" && (
                <>
                  <Nav.Link as={NavLink} to="/" activeClassName="active">
                    <MdOutlineDashboard className="me-1" />
                    Dashboard
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/user/buytoken"
                    activeClassName="active"
                  >
                    <SiBlockchaindotcom className="me-1" />
                    Buy Token
                  </Nav.Link>

                  <Nav.Link
                    as={NavLink}
                    to="/user/transactions"
                    activeClassName="active"
                  >
                    <FaArrowRightArrowLeft className="me-1" />
                    Transactions
                  </Nav.Link>

                  <Nav.Link
                    as={NavLink}
                    to="https://stellargold.net/explorer.html"
                    activeClassName="active"
                  >
                    <GiStoneBlock className="me-1" />
                    Explorer
                  </Nav.Link>
                </>
              )}
            </Nav>
            <Nav>
              <NavDropdown title="Profile" id="collapsible-nav-dropdown">
                <NavDropdown.Item
                  as={NavLink}
                  to="/user/profile"
                  activeClassName="active"
                >
                  Profile Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Topnavbar;
