import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Settings from "../../component/Settings";
import axios from "axios";
import { toast } from "react-toastify";

function General() {
  const { item } = Settings();
  const storedToken = localStorage.getItem("token");
  useEffect(() => {
    console.log(item.price);
  }, [item]);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    try {
      const response = await axios.post(
        "https://demo.urltd.online/admin/general",
        {
          price: form.elements.token.value,
          sale: form.elements.sale.value,
          telegram: form.elements.telegram.value,
          instagram: form.elements.instagram.value,
          facebook: form.elements.facebook.value,
          wp: form.elements.wp.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Update successful.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warn("Unwanted Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  return (
    <>
      <div className="mt-3 mb-4">
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="token">
            <Form.Label>Token Price</Form.Label>
            <Form.Control
              type="text"
              placeholder={item.price || "0.0"}
              defaultValue={item.price}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="sale">
            <Form.Label>Sale Offer</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder={item.sale || 0.0}
                defaultValue={item.sale}
              />
              <button className="btn bg-white text-dark">%</button>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="telegram">
            <Form.Label>Telegram</Form.Label>
            <Form.Control
              type="text"
              placeholder={item.telegram || "https://t.me/demo"}
              defaultValue={item.telegram || null}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="instagram">
            <Form.Label>Twitter</Form.Label>
            <Form.Control
              type="text"
              placeholder={item.instagram || "https://example.com"}
              defaultValue={item.instagram || null}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="facebook">
            <Form.Label>Facebook</Form.Label>
            <Form.Control
              type="text"
              placeholder={item.facebook || "https://example.com"}
              defaultValue={item.facebook || null}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="wp">
            <Form.Label> WhitePaper</Form.Label>
            <Form.Control
              type="text"
              placeholder={item.wp || "https://example.com"}
              defaultValue={item.wp || null}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <button className="cardx btn text-white fw-bold" type="submit">
              Update
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default General;
