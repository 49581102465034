import React, { useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MdEditSquare } from "react-icons/md";
import { Form } from "react-bootstrap";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";
import { LuHistory } from "react-icons/lu";
import { toast } from "react-toastify";

function Histroy() {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("All");
  const storedToken = localStorage.getItem("token");
  const [editItem, setEditItem] = React.useState({});

  const handleClickOpen = (item) => {
    setEditItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (newFilterType) => {
    setFilterType(newFilterType);
  };

  const filteredData = data.filter(
    (item) =>
      item.type !== "Reward" &&
      item.tnx_id.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filterType === "All" || item.status === filterType)
  );

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://demo.urltd.online/transactions",
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [storedToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const handlesubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    try {
      const response = await axios.post(
        `https://demo.urltd.online/admin/paid/${editItem.tnx_id}`,
        {
          status: form.elements.status.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        fetchData();
        setOpen(false);
        toast.success("Update Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setOpen(false);
    }
  };
  return (
    <div className="mt-4">
      <h5 className="mb-4">
        <LuHistory className="me-1" />
        Transactions History
      </h5>
      <ThemeProvider theme={darkTheme}>
        <React.Fragment>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <h4>PAYMENT STATAUS </h4>
              <p className="text-white-50">
                You can change payment status anytime for users.{" "}
              </p>
              <Form onSubmit={handlesubmit}>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Wallet Address</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={editItem.email}
                    placeholder="Basic Plan"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="status">
                  <Form.Label>Change payment status</Form.Label>
                  <Form.Select>
                    <option value="success">Success</option>
                    <option value="pending">Pending</option>
                  </Form.Select>
                </Form.Group>
                <button
                  className="w-100 text-white fw-bold btn cardx"
                  type="submit"
                >
                  Update
                </button>
              </Form>
            </DialogContent>
          </Dialog>
        </React.Fragment>

        <div>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <div className="mb-2">
            <button
              className={`btn btn-outline-primary me-2 ${
                filterType === "All" && "active"
              }`}
              onClick={() => handleFilterChange("All")}
            >
              All
            </button>
            <button
              className={`btn btn-outline-primary me-2 ${
                filterType === "pending" && "active"
              }`}
              onClick={() => handleFilterChange("pending")}
            >
              Pending
            </button>
            <button
              className={`btn btn-outline-primary ${
                filterType === "success" && "active"
              }`}
              onClick={() => handleFilterChange("success")}
            >
              Success
            </button>
          </div>

          <TableContainer component={Paper}>
            <Table>
              <TableHead className="cardx">
                <TableRow>
                  <TableCell>
                    <span className="bold">TRANX NO</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TOKENS</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">FROM</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TYPE</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">STATUS</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">TIME</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">ACTIONS</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell className="text-center" colSpan={6}>
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .reverse()
                    .map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.tnx_id}</TableCell>
                        <TableCell>{item.tokes} XLMS</TableCell>
                        <TableCell>
                          {" "}
                          {item.wallet.length > 12
                            ? `${item.wallet.slice(0, 8)}...${item.wallet.slice(
                                -6
                              )}`
                            : item.wallet}
                        </TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>
                          <button
                            className={`badge p-1 bg-opacity-10 border  border-opacity-25  ${
                              item.status === "pending"
                                ? "bg-danger text-danger border-danger"
                                : "bg-success text-success border-success"
                            }`}
                          >
                            {item.status}
                          </button>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {new Date(item.created_at).toLocaleString()}
                        </TableCell>

                        <TableCell>
                          <MdEditSquare
                            onClick={() => handleClickOpen(item)}
                            style={{ fontSize: 18, cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default Histroy;
