import React, { useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ParticleComponent from "../component/ParticleComponent";
import axios from "axios";

function Forget() {
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = {
        email: form.elements.email.value,
      };

      try {
        const response = await axios.post(
          "https://demo.urltd.online/auth/forget-password",
          formData
        );
        setShowAlert(true);
        console.log(response);
        navigate("/", {
          state: {
            signupSuccess: true,
            alertMessage:
              "Password reset successful! Kindly check your email for further instructions.",
          },
        });
      } catch (error) {
        setAlertMessage(error.response.data.error);
        setShowAlert(true);
      }
    }

    setValidated(true);
  };

  return (
    <>
      <div className=" vh-100  ">
        <ParticleComponent />

        <div className="container vh-100 d-flex justify-content-center align-items-center">
          <div
            className="card bg-darkX text-white moon"
            style={{ width: "27rem" }}
          >
            <h5>Forget your password?</h5>
            <p className="text-white-50">
              Your password will be reset by email.
            </p>
            {showAlert && (
              <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  required
                  name="email"
                  placeholder="Enter email"
                />
              </Form.Group>

              <div className="d-grid gap-2">
                <button className="btn cardx text-white" type="submit">
                  Reset Password
                </button>
              </div>
            </Form>
            <p className="text-center mt-4">
              <Link to="/" style={{ textDecoration: "none" }}>
                Back to login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Forget;
