import React, { useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaTelegram } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import ParticleComponent from "../component/ParticleComponent";
import axios from "axios";
import Settings from "../component/Settings";
import { IoEye, IoEyeOff } from "react-icons/io5";
import Button from "react-bootstrap/Button";

function Signup() {
  const { item } = Settings();
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = {
        wallet: form.elements.wallet.value,
        email: form.elements.email.value,
        password: form.elements.formBasicPassword.value,
      };

      try {
        const response = await axios.post(
          "https://demo.urltd.online/auth/signup",
          formData
        );
        setShowAlert(true);
        console.log(response);
        navigate("/", {
          state: {
            signupSuccess: true,
            alertMessage: "Signup successful! You can now login.",
          },
        });
      } catch (error) {
        setAlertMessage(error.response.data.error);
        setShowAlert(true);
      }
    }

    setValidated(true);
  };

  return (
    <>
      <div className=" vh-100  ">
        <ParticleComponent />

        <div className="container vh-100 d-flex justify-content-center align-items-center">
          <div
            className="card bg-darkX text-white moon"
            style={{ width: "27rem" }}
          >
            <h3>Welcome to Stellargold</h3>
            <p className="p">You don't have an account yet. Register now.</p>

            {showAlert && (
              <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                {alertMessage}
              </Alert>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicWallet">
                <Form.Label className="font-weight-bold">Wallet</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="wallet"
                  placeholder="GDM5W6QVIDN23NFYSJ5BTV74LXX5YUUM6H2B5YHN4QTHSSBHTXGALP36"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="font-weight-bold">Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  name="email"
                  placeholder="Enter email"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <IoEyeOff /> : <IoEye />}
                  </Button>
                </div>
              </Form.Group>

              <div className="d-grid gap-2">
                <button className="btn cardx text-white" type="submit">
                  Sign Up
                </button>
              </div>
            </Form>
            <p className="text-center mt-4">
              Already have an account?{" "}
              <Link to="/" style={{ textDecoration: "none" }}>
                <b className="text-primaryX">Sign In</b>
              </Link>
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <Link to={item.telegram} className="text-white-50">
                <div className="i">
                  <FaTelegram />
                </div>
              </Link>
              <Link to={item.facebook} className="text-white-50">
                <div className="i">
                  <FaFacebook />
                </div>
              </Link>
              <Link to={item.instagram} className="text-white-50">
                <div className="i">
                  <AiFillTwitterCircle />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
