import React from "react";
import { FaTelegram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import Settings from "../component/Settings";
import { Link } from "react-router-dom";

function FooterX() {
  const currentYear = new Date().getFullYear();
  const { item } = Settings();

  return (
    <div className="Footer d-none d-lg-block">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 order-2 order-md-1 col-md-12 col-sm-12  mb-2">
            <p>©{currentYear} TokenLite. All Right Reserved.</p>
          </div>
          <div className="col-lg-4 order-2 icon order-md-1 col-md-12 col-sm-12  mb-2 ">
            <Link to={item.telegram} className="text-white-50">
              <div className="i">
                <FaTelegram />
              </div>
            </Link>
            <Link to={item.facebook} className="text-white-50">
              <div className="i">
                <FaFacebook />
              </div>
            </Link>
            <Link to={item.instagram} className="text-white-50">
              <div className="i">
                <AiFillTwitterCircle />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterX;
