import React, { useState, useEffect } from "react";
import "./App.css";
import { jwtDecode } from "jwt-decode";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import {
  Login,
  Signup,
  AdminDashboard,
  Error,
  Transactions,
  UserDashboard,
  Profile,
  Buy,
  Forget,
} from "./pages/index";
import { ProtectedRoute, Topnavbar } from "./component/Index";

import LoadingBar from "react-top-loading-bar";

function App() {
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const token = localStorage.getItem("token");
  useEffect(() => {
    const handleRouteChange = () => {
      setProgress(30);
      setTimeout(() => {
        setProgress(100);
      }, 0);
    };

    handleRouteChange();

    return () => {
      setProgress(0);
    };
  }, [location]);

  const handleLoaderClick = () => {
    setProgress(0);
  };

  return (
    <>
      <LoadingBar
        color="#F34772"
        progress={progress}
        onLoaderClick={handleLoaderClick}
      />
      {token && <Topnavbar />}

      <Routes>
        <Route
          exact
          path="/"
          element={
            token ? (
              jwtDecode(token).role === "admin" ? (
                <Navigate to="/admin/dashboard" replace />
              ) : (
                <Navigate to="/user/dashboard" replace />
              )
            ) : (
              <Login />
            )
          }
        />

        <Route
          exact
          path="/signup"
          element={
            token ? (
              jwtDecode(token).role === "admin" ? (
                <Navigate to="/admin/dashboard" replace />
              ) : (
                <Navigate to="/user/dashboard" replace />
              )
            ) : (
              <Signup />
            )
          }
        />

        <Route
          exact
          path="/reset-password"
          element={
            token ? (
              jwtDecode(token).role === "admin" ? (
                <Navigate to="/admin/dashboard" replace />
              ) : (
                <Navigate to="/user/dashboard" replace />
              )
            ) : (
              <Forget />
            )
          }
        />

        <Route
          exact
          path="/user/dashboard"
          element={
            <ProtectedRoute roles={["user"]} component={UserDashboard} />
          }
        />
        <Route
          exact
          path="/user/transactions"
          element={<ProtectedRoute roles={["user"]} component={Transactions} />}
        />
        <Route
          exact
          path="/user/profile"
          element={
            <ProtectedRoute roles={["user", "admin"]} component={Profile} />
          }
        />

        <Route
          exact
          path="/user/buytoken"
          element={<ProtectedRoute roles={["user"]} component={Buy} />}
        />
        <Route
          exact
          path="/admin/dashboard"
          element={
            <ProtectedRoute roles={["admin"]} component={AdminDashboard} />
          }
        />

        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
