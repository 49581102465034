import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
const ParticleComponent = () => {
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };
  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        background: {
          color: {
            value: "#0A0B0D", // Set the background color
          },
        },
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#ffffff", // Set the color of the snowflakes
          },
          shape: {
            type: "circle",
          },
          opacity: {
            value: 0.7,
            random: true,
          },
          size: {
            value: 2.5,
            random: true,
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            direction: "bottom",
            speed: 0.8,
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
            },
            onclick: {
              enable: false,
            },
            resize: true,
          },
        },
        retina_detect: true,
      }}
    />
  );
};
export default ParticleComponent;
