import { useEffect, useState } from "react";
import axios from "axios";
import UserInfo from "./UserInfo";

function RewordComponent() {
  const [totalTokes, setTotalTokes] = useState(0);
  const storedToken = localStorage.getItem("token");
  const { userProperties } = UserInfo({ data: ["uid"] });
  const uid = userProperties.uid;
  const transactionType = "Reward";

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          "https://demo.urltd.online/transactions",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        const apiResponse = response.data;
        const filteredData = apiResponse.data.filter(
          (item) => item.uid === uid && item.type === transactionType
        );
        const sumTokes = filteredData.reduce(
          (acc, item) => acc + parseFloat(item.tokes),
          0
        );
        setTotalTokes(sumTokes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTransactions();
  }, [uid, storedToken, totalTokes]);

  return <>{totalTokes}</>;
}

export default RewordComponent;
