import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import UserInfo from "../component/UserInfo";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { LuHistory } from "react-icons/lu";

function Transactions() {
  const { userProperties } = UserInfo({ data: ["uid"] });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const storedToken = localStorage.getItem("token");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.urltd.online/transactions",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [storedToken]);

  return (
    <div className="container mt-4">
      <div className="p-4 card">
        <h5 className="mb-4">
          <LuHistory className="me-1" />
          Transactions list
        </h5>
        <ThemeProvider theme={darkTheme}>
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="cardx">
                  <TableRow>
                    <TableCell>
                      <span className="bold">TRANX NO</span>
                    </TableCell>
                    <TableCell>
                      <span className="bold">TOKENS</span>
                    </TableCell>
                    <TableCell>
                      <span className="bold">TO </span>
                    </TableCell>
                    <TableCell>
                      <span className="bold">TYPE</span>
                    </TableCell>
                    <TableCell>
                      <span className="bold">STATUS</span>
                    </TableCell>
                    <TableCell>
                      <span className="bold">TIME</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell className="text-center" colSpan={6}>
                        No data available
                      </TableCell>
                    </TableRow>
                  ) : (
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .reverse()
                      .map(
                        (item) =>
                          item.uid === userProperties.uid && (
                            <TableRow key={item.id}>
                              <TableCell>{item.tnx_id}</TableCell>
                              <TableCell>{item.tokes} XLMS</TableCell>
                              <TableCell>
                                {" "}
                                {item.wallet.length > 12
                                  ? `${item.wallet.slice(
                                      0,
                                      8
                                    )}...${item.wallet.slice(-6)}`
                                  : item.wallet}
                              </TableCell>
                              <TableCell>{item.type}</TableCell>
                              <TableCell>
                                <button
                                  className={`badge p-1 bg-opacity-10 border  border-opacity-25  ${
                                    item.status === "pending"
                                      ? "bg-danger text-danger border-danger"
                                      : "bg-success text-success border-success"
                                  }`}
                                >
                                  {item.status}
                                </button>
                              </TableCell>
                              <TableCell>
                                {" "}
                                {new Date(item.created_at).toLocaleString()}
                              </TableCell>
                            </TableRow>
                          )
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Transactions;
