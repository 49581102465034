import React from "react";
import { FaDownload } from "react-icons/fa";
import { GiTrophyCup } from "react-icons/gi";
import { Link } from "react-router-dom";
import { BiSolidCoinStack } from "react-icons/bi";
import { FaCoins } from "react-icons/fa";
import Token from "../images/token.png";
import Hero from "../images/x.png";

import {
  TokenBalance,
  Stake,
  FooterX,
  RewordComponent,
  Settings,
} from "../component/Index";

function UserDashboard() {
  const { item } = Settings();
  const { balance: xlmgBalance } = TokenBalance(
    "XLMG-GCVNN7O5JISPEYUTLK3JYGBDWCPDIHB4MTG4PMSJVIKJCR64NOXWI3YH-1"
  );
  const { balance: xlmsBalance } = TokenBalance(
    "XLMS-GA7TYAZ32F4B2SIDLUFZFTLGWGEP7INRZHQ4YFMCQZ5RXSEJSREWXLMS-1"
  );

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
          <div className=" cardx p-4">
            <h6>
              <FaCoins /> TOKEN BALANCE
            </h6>
            <h4>{xlmgBalance} XLMG</h4>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className=" cardz p-4">
            <h6>
              <BiSolidCoinStack /> TOKEN BALANCE
            </h6>
            <h4>{xlmsBalance} XLMS</h4>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="cardy p-4">
            <h6>
              <GiTrophyCup /> TOTAL REWARDS
            </h6>
            <h4>
              <RewordComponent /> XLMS
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
          <div className=" card p-4">
            <div className="row">
              <div className="col-md-3 d-flex justify-content-center align-items-center col-sm-12">
                <img src={Hero} width={200} alt="hero" />
              </div>
              <div className="col-md-8 col-sm-12">
                <h3>
                  Thank you for your interest in our Stellar Silver Rewards
                </h3>
                <p>
                  You can contribute in XLMS rewards and in different tiers and
                  can Buy XLMS also. For quick responses and project
                  discussions, join our Telegram.{" "}
                  <Link to={item.telegram}>{item.telegram}</Link>
                </p>
                <b> Explore the rewards awaiting you!</b>
                <br />
                <Link className="App-link" to={item.wp}>
                  <button className="btn  cardx text-white fw-bold align-items-center justify-content-center text-white d-flex mt-2">
                    {" "}
                    <FaDownload className="me-2" />
                    <span> Download Whitepaper</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
          <div
            className=" card p-4"
            style={{ height: 275, overflow: "hidden" }}
          >
            <img src={Token} alt="token" width="100%" height={275} />
          </div>
        </div>
      </div>
      <Stake />
      <FooterX />
    </div>
  );
}

export default UserDashboard;
