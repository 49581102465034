import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { TokenBalance, UserInfo } from "../component/Index";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { toast } from "react-toastify";
import axios from "axios";

function Profile() {
  const { userProperties } = UserInfo({ data: ["wallet", "email", "uid"] });
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const storedToken = localStorage.getItem("token");
  const { balance: xlmgBalance } = TokenBalance(
    "XLMG-GCVNN7O5JISPEYUTLK3JYGBDWCPDIHB4MTG4PMSJVIKJCR64NOXWI3YH-1"
  );
  const { balance: xlmsBalance } = TokenBalance(
    "XLMS-GA7TYAZ32F4B2SIDLUFZFTLGWGEP7INRZHQ4YFMCQZ5RXSEJSREWXLMS-1"
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const UpdateData = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const response = await axios.post(
          `https://demo.urltd.online/profile/${userProperties.uid}`,
          {
            email: form.elements.formBasicEmail.value,
            password: form.elements.formBasicPassword.value,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          toast.success("Plan action successful.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          const { token } = response.data;
          localStorage.setItem("token", token);
          setTimeout(() => {
            window.location.reload(true);
          }, 500);
        } else {
          toast.error("Server retrun 401 ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } catch (error) {
        toast.error("Server retrun 401 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    setValidated(true);
  };
  return (
    <div className="container mt-43">
      <div className="row mt-4">
        <div className="col-lg-8 order-2 order-md-1 col-md-12 col-sm-12  mb-2">
          <div className="card p-4">
            <h6>Profile Details</h6>
            <p>Update your profile information anytime.</p>
            <Form noValidate validated={validated} onSubmit={UpdateData}>
              <Form.Group className="mb-3" controlId="formBasicwallet">
                <Form.Label>Wallet address</Form.Label>
                <Form.Control
                  className="bg-gray text-white-50"
                  type="text"
                  placeholder="Wallet Address"
                  defaultValue={userProperties.wallet}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  defaultValue={userProperties.email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <IoEyeOff /> : <IoEye />}
                  </Button>
                </div>
              </Form.Group>

              <div className="d-grid gap-2">
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="col-lg-4 order-1 order-md-2 col-md-12 col-sm-12 mb-2">
          <div className="cardx p-4 mb-3">
            <h6 className="mt-1">XLMG BALANCE</h6>
            <h5>{xlmgBalance} XLMG</h5>
          </div>

          <div className="card p-3 mb-3">
            <h6 className="mt-1">XLMS BALANCE</h6>
            <h5>{xlmsBalance} XLMS</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
