import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

function UserInfo({ data }) {
  const storedToken = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    let decodedToken = null;
    try {
      decodedToken = jwtDecode(storedToken);
      setUserData(decodedToken.results[0] || null);
    } catch (error) {
      console.error("Error decoding JWT:", error);
    }
  }, [storedToken]);

  const userProperties = {};

  if (userData) {
    data.forEach((property) => {
      userProperties[property] = userData[property] || null;
    });
  }

  return { userProperties };
}

export default UserInfo;
