import { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function useTokenBalance(token) {
  const [balance, setBalance] = useState("0.00");

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const storedToken = localStorage.getItem("token");
        const decode = jwtDecode(storedToken);

        const accountResponse = await axios.get(
          `https://horizon.stellar.org/accounts/${decode.results[0].wallet}`
        );

        const accountData = accountResponse.data;
        const [code, issuer] = token.split("-");
        const tokenBalance = accountData.balances.find(
          (balance) =>
            balance.asset_issuer === issuer && balance.asset_code === code
        );

        setBalance(tokenBalance ? tokenBalance.balance : "0.00");
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalance();
  }, [token]);

  return { balance };
}

export default useTokenBalance;
