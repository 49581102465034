import { useEffect, useState } from "react";
import axios from "axios";

function TotalUser() {
  const [dataAmount, setDataAmount] = useState(0);
  const storedToken = localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.urltd.online/admin/userlist",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const apiResponse = response.data;
        const data = apiResponse.data || [];

        const amount = data.length;

        setDataAmount(amount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [storedToken]);

  return <div>{dataAmount}</div>;
}

export default TotalUser;
