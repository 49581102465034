import React, { useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Form } from "react-bootstrap";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
function Gateway() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState({});

  const storedToken = localStorage.getItem("token");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditClickOpen = (item) => {
    setEditItem(item);
    setEditDialogOpen(true);
  };

  const editsubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    try {
      const response = await axios.post(
        `https://demo.urltd.online/admin/gateway/update/${editItem.id}`,
        {
          name: form.elements.name.value,
          address: form.elements.address.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        fetchData();
        setEditDialogOpen(false);
        toast.success("Update Success ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setEditDialogOpen(false);
    }
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    try {
      const response = await axios.post(
        "https://demo.urltd.online/admin/gateway",
        {
          name: form.elements.name.value,
          address: form.elements.address.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        fetchData();
        setOpen(false);
        toast.success("Gateway Added ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setOpen(false);
    }
  };
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`https://demo.urltd.online/gateway`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [storedToken]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            `https://demo.urltd.online/admin/gateway/delete/${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            fetchData();
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Failed to delete the file. Please try again.",
              icon: "error",
            });
          }
        } catch (error) {
          console.error("Error deleting item:", error);

          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred. Please try again.",
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div className="mt-4">
      <ThemeProvider theme={darkTheme}>
        <React.Fragment>
          <div className=" d-flex justify-content-between justify-content-between">
            <h5>Gateway Manager</h5>

            <Button variant="outlined" onClick={handleClickOpen}>
              Add New
            </Button>
          </div>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <h4>Add New Gateway </h4>
              <p className="text-white-50">
                Add your favorite cryptocurrency to accept payments.{" "}
              </p>
              <Form onSubmit={handlesubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Crypto Name</Form.Label>
                  <Form.Control required type="text" placeholder="bitcoin" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Wallet Address</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="1Gx9FCknxSsLfFDzFdn75Xgqx95sDp38ir"
                  />
                </Form.Group>
                <button
                  className="w-100 text-white fw-bold btn cardx"
                  type="submit"
                >
                  Add Now
                </button>
              </Form>
            </DialogContent>
          </Dialog>

          <Dialog open={editDialogOpen} onClose={handleEditClose}>
            <DialogContent>
              <h4>Update Gateway </h4>
              <p className="text-white-50">
                Add your favorite cryptocurrency to accept payments.{" "}
              </p>
              <Form onSubmit={editsubmit}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Crypto Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="bitcoin"
                    defaultValue={editItem.name || null}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Wallet Address</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={editItem.address || null}
                    placeholder="1Gx9FCknxSsLfFDzFdn75Xgqx95sDp38ir"
                  />
                </Form.Group>
                <button
                  className="w-100 text-white fw-bold btn cardx"
                  type="submit"
                >
                  Update
                </button>
              </Form>
            </DialogContent>
          </Dialog>
        </React.Fragment>

        <TableContainer className="mt-4" component={Paper}>
          <Table>
            <TableHead className="cardx">
              <TableRow>
                <TableCell>
                  <span className="bold">Name</span>
                </TableCell>
                <TableCell>
                  <span className="bold">Address</span>
                </TableCell>
                <TableCell>
                  <span className="bold">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell className="text-center" colSpan={6}>
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .reverse()
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.address}</TableCell>
                      <TableCell>
                        <MdEditSquare
                          onClick={() => handleEditClickOpen(item)}
                          style={{ fontSize: 18, cursor: "pointer" }}
                        />
                        <MdDelete
                          onClick={() => handleDelete(item.id)}
                          className="text-danger"
                          style={{ fontSize: 20, cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </div>
  );
}

export default Gateway;
