import React, { useState, useEffect, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserInfo from "../component/UserInfo";
import { toast } from "react-toastify";
import { TokenBalance } from "../component/Index";
import SeedIcon from "./SeedIcon";
function Stake() {
  const navigate = useNavigate();
  const { balance: xlmgBalance } = TokenBalance(
    "XLMG-GCVNN7O5JISPEYUTLK3JYGBDWCPDIHB4MTG4PMSJVIKJCR64NOXWI3YH-1"
  );
  const { balance: xlmsBalance } = TokenBalance(
    "XLMS-GA7TYAZ32F4B2SIDLUFZFTLGWGEP7INRZHQ4YFMCQZ5RXSEJSREWXLMS-1"
  );

  const storedToken = localStorage.getItem("token");
  const { userProperties } = UserInfo({ data: ["uid", "active_plan"] });
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const formatNumber = (num) => {
    const numericValue = parseFloat(num);
    if (isNaN(numericValue)) {
      return num;
    }
    if (numericValue < 1000) return numericValue.toFixed(2);
    if (numericValue < 1000000) return (numericValue / 1000).toFixed(2) + "K";
    if (numericValue < 1000000000)
      return (numericValue / 1000000).toFixed(2) + "M";
    if (numericValue < 1000000000000)
      return (numericValue / 1000000000).toFixed(2) + "B";
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get("https://demo.urltd.online/stake", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [storedToken]);

  const activePlan = async (value) => {
    try {
      const response = await axios.post(
        `https://demo.urltd.online/stake/plan/${userProperties.uid}`,
        {
          plan: value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Action Successful.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        const { token } = response.data;
        localStorage.setItem("token", token);
        setTimeout(() => {
          navigate("/user/dashboard");
        }, 500);
      } else {
        toast.error("Server retrun 401 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="row">
        <h4 className="text-center text-white mb-4">STAKE TOKENS</h4>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="mb-4" />
            {data.length === 0 ? (
              <>
                <p className="text-center text-white">No data found!</p>
              </>
            ) : (
              data.slice().map((item) => (
                <div
                  key={item.id}
                  className="col-lg-4 col-md-12 col-sm-12 mb-4"
                >
                  <div className="cardb p-4 text-center">
                    <SeedIcon address={item.title} />
                    <p style={{ margin: "-1px" }}>
                      <b className="text-white">{item.title}</b>
                    </p>
                    <p
                      className="text-warning mb-4"
                      style={{ fontSize: "14px" }}
                    >
                      To activate your plan, you need to meet the minimum token
                      requirements. [ {formatNumber(item.xlmg)} XLMG &{" "}
                      {formatNumber(item.xlms)} XLMS ]
                    </p>

                    <div className="d-flex justify-content-between align-items-center">
                      <p className="text-white" style={{ fontSize: "12px" }}>
                        APY :
                      </p>
                      <b className="text-success" style={{ fontSize: "12px" }}>
                        {item.apy}%
                      </b>
                    </div>
                    <div className="w-h" />

                    <div className="d-flex  justify-content-between align-items-center">
                      <p className="text-white" style={{ fontSize: "12px" }}>
                        Earn :
                      </p>
                      <b className="text-white" style={{ fontSize: "12px" }}>
                        XLMS
                      </b>
                    </div>

                    <div class="d-grid gap-2">
                      {item.sid === userProperties.active_plan &&
                      xlmgBalance >= parseFloat(item.xlmg) &&
                      xlmsBalance >= parseFloat(item.xlms) ? (
                        <button className="btn fw-bold btn-success text-white">
                          Activated
                        </button>
                      ) : (
                        <>
                          {xlmgBalance >= parseFloat(item.xlmg) &&
                          xlmsBalance >= parseFloat(item.xlms) ? (
                            <button
                              className="btn cardx text-white fw-bold"
                              type="button"
                              onClick={() => activePlan(item.sid)}
                            >
                              Claim
                            </button>
                          ) : (
                            <button className="cardy btn text-white fw-bold">
                              Require Tokens
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Stake;
