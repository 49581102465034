import React, { useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Form } from "react-bootstrap";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
function Stake() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState({});

  const storedToken = localStorage.getItem("token");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditClickOpen = (item) => {
    setEditItem(item);
    setEditDialogOpen(true);
  };

  const editsubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    try {
      const response = await axios.post(
        `https://demo.urltd.online/stake/update/${editItem.id}`,
        {
          title: form.elements.title.value,
          apy: form.elements.apy.value,
          xlmg: form.elements.xlmg.value,
          xlms: form.elements.xlms.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        fetchData();
        setEditDialogOpen(false);
        toast.success("Update Success ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setEditDialogOpen(false);
    }
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    try {
      const response = await axios.post(
        "https://demo.urltd.online/stake/add",
        {
          title: form.elements.title.value,
          apy: form.elements.apy.value,
          xlmg: form.elements.xlmg.value,
          xlms: form.elements.xlms.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        fetchData();
        setOpen(false);
        toast.success("Plan Added ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Server retrun 401 ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setOpen(false);
    }
  };
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get("https://demo.urltd.online/stake", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [storedToken]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            `https://demo.urltd.online/stake/delete/${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            fetchData();
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Failed to delete the file. Please try again.",
              icon: "error",
            });
          }
        } catch (error) {
          console.error("Error deleting item:", error);

          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred. Please try again.",
            icon: "error",
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div className="mt-4 p-2">
      <ThemeProvider theme={darkTheme}>
        <React.Fragment>
          <div className="d-flex justify-content-between justify-content-between">
            <h5>Plan Manager</h5>
            <Button variant="outlined" onClick={handleClickOpen}>
              Add New
            </Button>
          </div>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <h4>ADD NEW PLAN </h4>
              <p className="text-white-50">
                add stakeing plan for your system.So user can get rewads .{" "}
              </p>
              <Form onSubmit={handlesubmit}>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>TITLE</Form.Label>
                  <Form.Control required type="text" placeholder="Basic Plan" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="apy">
                  <Form.Label>APY</Form.Label>
                  <div className="d-flex">
                    <Form.Control required type="number" placeholder="0.00" />
                    <button className="btn bg-white">%</button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="xlmg">
                  <Form.Label>MIN XLMG</Form.Label>
                  <Form.Control required type="number" placeholder="0.00" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="xlms">
                  <Form.Label>MIN XLMS</Form.Label>
                  <Form.Control required type="number" placeholder="0.00" />
                </Form.Group>

                <button
                  className="w-100 text-white fw-bold btn cardx"
                  type="submit"
                >
                  Add Now
                </button>
              </Form>
            </DialogContent>
          </Dialog>

          <Dialog open={editDialogOpen} onClose={handleEditClose}>
            <DialogContent>
              <h4>Update Plan</h4>
              <p className="text-white-50">
                update stakeing plan for your system.So user can get rewads .{" "}
              </p>
              <Form onSubmit={editsubmit}>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>TITLE</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={editItem.title}
                    placeholder="Basic Plan"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="apy">
                  <Form.Label>APY</Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      required
                      type="number"
                      defaultValue={editItem.apy}
                      placeholder="0.00"
                    />
                    <button className="btn bg-white">%</button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="xlmg">
                  <Form.Label>MIN XLMG</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    defaultValue={editItem.xlmg}
                    placeholder="0.00"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="xlms">
                  <Form.Label>MIN XLMS</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    defaultValue={editItem.xlms}
                    placeholder="0.00"
                  />
                </Form.Group>

                <button
                  className="w-100 text-white fw-bold btn cardx"
                  type="submit"
                >
                  Add Now
                </button>
              </Form>
            </DialogContent>
          </Dialog>
        </React.Fragment>

        <TableContainer className="mt-4" component={Paper}>
          <Table>
            <TableHead className="cardx">
              <TableRow>
                <TableCell>
                  <span className="bold">TITLE</span>
                </TableCell>
                <TableCell>
                  <span className="bold">APY</span>
                </TableCell>
                <TableCell>
                  <span className="bold">MIN XLMG</span>
                </TableCell>
                <TableCell>
                  <span className="bold">MIN XLMS</span>
                </TableCell>
                <TableCell>
                  <span className="bold">ACTION</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell className="text-center" colSpan={6}>
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .reverse()
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>
                        <span className="text-success">{item.apy}%</span>
                      </TableCell>

                      <TableCell>{item.xlmg}</TableCell>
                      <TableCell>{item.xlms}</TableCell>
                      <TableCell>
                        <MdEditSquare
                          onClick={() => handleEditClickOpen(item)}
                          style={{ fontSize: 18, cursor: "pointer" }}
                        />
                        <MdDelete
                          onClick={() => handleDelete(item.id)}
                          className="text-danger"
                          style={{ fontSize: 20, cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </div>
  );
}

export default Stake;
