import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div className="container mt-4 text-center card p-4">
      <div className="pt-4" />
      <div className="pt-4" />

      <h1>404</h1>
      <b>Oops, you still haven't found what you're looking for?</b>
      <p>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable
      </p>
      <Link to="/">
        <button className="btn cardx text-white fw-bold">
          Back to Home Page
        </button>
      </Link>

      <div className="pt-4" />
      <div className="pt-4" />
    </div>
  );
}

export default Error;
