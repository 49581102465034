import { useState, useEffect, useCallback } from "react";
import axios from "axios";

function Settings() {
  const [data, setData] = useState([]);
  const storedToken = localStorage.getItem("token");

  const getData = useCallback(async () => {
    try {
      const response = await axios.get("https://demo.urltd.online/general", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });
      setData(response.data.data || []);
    } catch (error) {
      console.log(error);
    }
  }, [storedToken]);

  useEffect(() => {
    getData();
  }, [getData]);

  const item = data.length > 0 ? data[0] : {};

  return { item };
}

export default Settings;
