import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { BiSolidCoinStack } from "react-icons/bi";
import { GiTrophyCup } from "react-icons/gi";
import { FaRegUserCircle } from "react-icons/fa";
import {
  General,
  Transactions,
  Gateway,
  Stake,
  User,
  TotalDataAmount,
  Histroy,
  RewadData,
  TotalUser,
} from "./admin/Index";
function AdminDashboard() {
  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
            <div className=" cardx p-4">
              <h6>
                <FaRegUserCircle /> TOKEN USERS
              </h6>
              <h4>
                <TotalUser />
              </h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className=" cardz p-4">
              <h6>
                <BiSolidCoinStack /> ACTIVE PLAN
              </h6>
              <h4>
                <TotalDataAmount />
              </h4>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="cardy p-4">
              <h6>
                <GiTrophyCup />
                REWARD PAID{" "}
              </h6>
              <h4>
                <RewadData /> XLMS
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container card p-4 mt-4 mb-4 rounded">
        <Tabs defaultActiveKey="tab1" id="my-tabs">
          <Tab eventKey="tab1" title="General">
            <General />
          </Tab>

          <Tab eventKey="tab2" title="Gateway">
            <Gateway />
          </Tab>

          <Tab eventKey="tab3" title="Stake Plan">
            <Stake />
          </Tab>

          <Tab eventKey="tab4" title="Users">
            <User />
          </Tab>

          <Tab eventKey="tab5" title="Rewards">
            <Transactions />
          </Tab>

          <Tab eventKey="tab6" title="Transactions">
            <Histroy />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default AdminDashboard;
