import { useEffect, useState } from "react";
import axios from "axios";

function RewadData() {
  const [totalTokes, setTotalTokes] = useState(0);
  const storedToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          "https://demo.urltd.online/transactions",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const apiResponse = response.data;
        const transactions = apiResponse.data || [];

        const sumTokes = transactions.reduce(
          (acc, item) =>
            acc + (item.type === "Reward" ? parseFloat(item.tokes) : 0),
          0
        );

        setTotalTokes(sumTokes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTransactions();
  }, [storedToken]);

  return <>{totalTokes}</>;
}

export default RewadData;
