import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Rewad from "../../component/Reword";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";

function User() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const storedToken = localStorage.getItem("token");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.urltd.online/admin/userlist",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [storedToken]);

  const filteredData = data.filter(
    (item) =>
      item.role === "user" &&
      item.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="mt-4">
      <h5 className="mb-4">ALL Users</h5>
      <ThemeProvider theme={darkTheme}>
        <div>
          {/* Search input */}
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <TableContainer component={Paper}>
            <Table>
              <TableHead className="cardx">
                <TableRow>
                  <TableCell>
                    <span className="bold">Email</span>
                  </TableCell>

                  <TableCell>
                    <span className="bold">WALLET</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">ACTIVE PLAN</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">REWARD</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">STATUS</span>
                  </TableCell>
                  <TableCell>
                    <span className="bold">JOIN TIME</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell className="text-center" colSpan={6}>
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .reverse()
                    .map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.email}</TableCell>

                        <TableCell>
                          <Link
                            to={`https://stellar.expert/explorer/public/account/${item.wallet}`}
                          >
                            {item.wallet.length > 12
                              ? `${item.wallet.slice(
                                  0,
                                  8
                                )}...${item.wallet.slice(-6)}`
                              : item.wallet}
                          </Link>
                        </TableCell>
                        <TableCell>{item.active_plan}</TableCell>
                        <TableCell>
                          <Rewad id={item.uid} />
                        </TableCell>
                        <TableCell>
                          <button className="badge p-1 bg-opacity-10 border  border-opacity-25 bg-success text-success border-success">
                            Active
                          </button>
                        </TableCell>
                        <TableCell>
                          {new Date(item.created_at).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default User;
