import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Popover, InputGroup } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SiHiveBlockchain } from "react-icons/si";
import axios from "axios";

const TokenSelector = ({ onSelectToken }) => {
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [availableTokens, setAvailableTokens] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://demo.urltd.online/gateway");
      const tokensFromApi = response.data.data.map((token) => token.name);
      setAvailableTokens(tokensFromApi);
      setApiResponse(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    onSelectToken(selectedToken);
  }, [selectedToken, onSelectToken]);

  const handleTokenChange = (event) => {
    const selectedToken = event.target.value;
    setSelectedToken(selectedToken);

    const selectedTokenData = apiResponse?.data.find(
      (token) => token.name === selectedToken
    );

    if (selectedTokenData) {
      setSelectedAddress(selectedTokenData.address);
      setIsCopied(false);
    } else {
      console.error(`Token data not found for ${selectedToken}`);
    }
  };

  const handleCopy = () => {
    setIsCopied(true);
  };

  const popover = (
    <Popover id="popover-copied" className="d-inline-block">
      {isCopied ? "Copied!" : "Copy"}
    </Popover>
  );

  return (
    <>
      <Form.Group controlId="tokenSelector">
        <Form.Label>Select Token:</Form.Label>
        <Form.Control
          as="select"
          onChange={handleTokenChange}
          value={selectedToken}
        >
          <option value="">Select...</option>
          {availableTokens.map((token) => (
            <option key={token} value={token}>
              {token}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {selectedToken && (
        <>
          <div className="d-flex mt-2 mb-2">
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <SiHiveBlockchain />
              </InputGroup.Text>
              <Form.Control value={selectedAddress} disabled />
              <InputGroup.Text>
                {" "}
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                  rootClose
                  onExited={() => setIsCopied(false)}
                >
                  <div style={{ width: "20px" }}>
                    <CopyToClipboard text={selectedAddress} onCopy={handleCopy}>
                      <FaCopy style={{ marginRight: "5px" }} />
                    </CopyToClipboard>
                  </div>
                </OverlayTrigger>
              </InputGroup.Text>
            </InputGroup>
          </div>
        </>
      )}
    </>
  );
};

export default TokenSelector;
