import React, { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaWallet } from "react-icons/fa6";
import { TokenSelector, UserInfo, Settings } from "../component/Index";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function Buy() {
  const { item } = Settings();
  const tokens = ["btc", "ltc", "usdt"];
  const storedToken = localStorage.getItem("token");
  const [validated, setValidated] = useState(false);
  const { userProperties } = UserInfo({ data: ["uid", "email", "wallet"] });
  const [amount, setAmount] = useState(1);
  const [xlmgValue, setXlmgValue] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [xlmgPrice, setxlmgPrice] = useState(0);
  const [selectedCrypto, setSelectedCrypto] = useState("btc");
  const [cryptoPrices, setCryptoPrices] = useState({});

  const convertToXlmg = useCallback(() => {
    const price = cryptoPrices[selectedCrypto];
    const xlmgVal = (amount * price) / xlmgPrice;
    setXlmgValue(xlmgVal);
  }, [amount, selectedCrypto, cryptoPrices, xlmgPrice]);

  useEffect(() => {
    convertToXlmg();
  }, [amount, selectedCrypto, convertToXlmg]);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
  };

  const handleSelectToken = (selectedToken) => {
    setSalePrice(item.sale || 0.0);
    setxlmgPrice(item.price || 0.0);
    axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${selectedToken}&vs_currencies=usd`
      )
      .then((response) => {
        const data = response.data;
        const tokenPrice = data[selectedToken]?.usd || 0.0;
        setCryptoPrices((prevPrices) => ({
          ...prevPrices,
          [selectedToken]: tokenPrice,
        }));
      })
      .catch((error) => console.error("Error fetching crypto prices:", error));
    setSelectedCrypto(selectedToken);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const response = await axios.post(
          `https://demo.urltd.online/buy/${userProperties.uid}`,
          {
            email: userProperties.wallet,
            wallet: form.elements.formwallet.value,
            type: selectedCrypto,
            token: xlmgValue + (xlmgValue / 100) * salePrice,
          },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Payment Request Success",
            text: "Upon completion of the verification process, the corresponding amounts will be meticulously reviewed.",
            icon: "success",
          });
        } else {
          toast.warn("Server Issue Found ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } catch (error) {
        toast.error("Server retrun 401 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
    setValidated(true);
  };

  return (
    <div className="container mt-43">
      <div className="row mt-4">
        <div className="col-lg-8 order-2 order-md-1 col-md-12 col-sm-12  mb-2">
          <div className="card p-4">
            <h6>Choose currency and calculate token price</h6>
            <p>
              You can buy our token using the below currency choices to become
              part of our project.
            </p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <TokenSelector
                tokens={tokens}
                onSelectToken={handleSelectToken}
              />
              <Form.Group className="mb-3" controlId="formwallet">
                <Form.Label>Sender Address</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="03FZbgi29cpjq2GjdwV8eeyHuJJnkLtktZc5x"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formToken">
                <Form.Label>Amount [ {selectedCrypto} ] </Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  required
                  onChange={handleAmountChange}
                  placeholder="0.00"
                />
                <Form.Text className="text-warning">
                  Enter the amount you would like to contribute in order to
                  calculate the amount of tokens you will receive. The
                  calculator below helps to convert the required quantity of
                  tokens into the amount of your selected currency.
                </Form.Text>
              </Form.Group>
              <div className="d-grid gap-2">
                <Button type="submit" variant="primary">
                  <FaWallet className="me-1" />
                  Make Payment
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <div className="col-lg-4 order-1 order-md-2 col-md-12 col-sm-12 mb-2">
          <div className="card p-4 mb-3">
            <h6 className="mt-1">SALE BONUS {salePrice}%</h6>
            <h5>{(xlmgValue / 100) * salePrice || 0.0} EXTRA</h5>
          </div>

          <div className="card p-3 mb-3">
            <h6 className="mt-1">TOKEN PRICE</h6>
            <p>{xlmgPrice || 0.0} USD</p>
          </div>

          <div className="cardx mb-3 p-3">
            <h6>TOTAL TOKEN</h6>
            <h5> {xlmgValue + (xlmgValue / 100) * salePrice || 0.0} XLMS</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
