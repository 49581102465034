import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

function ChartPage() {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: [
      "65% Crowdsale",
      "10% Team",
      "5% Advisors and Partners",
      "5% Beauty, Airdrop and Referrals",
      "15% Reserved for Agate Ecosystem",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [65, 10, 5, 5, 15],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} />;
}

export default ChartPage;
