import React from "react";
import Blockies from "react-blockies";

function SeedIcon({ address }) {
  return (
    <Blockies
      seed={address}
      size={10}
      scale={4}
      color="#dfe"
      className="identicon"
    />
  );
}

export default SeedIcon;
